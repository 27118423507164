import React from 'react';
import './AVRStyle.css';

export default function AVRPage(props) {

  return (
    <section className="AVRsection">
        <div className="ttHeader" id="questcequecestAVR-FLASHER">
            <h2>Qu'est-ce que le projet AVR-FLASHER</h2>
        </div>
        <div className="ttText">
            <p className="flash_p">
                <span style={{marginLeft:'30px'}}></span><i>Nous allons créer ici une petite application de bureau pour communiquer avec le microcontrôleur ATMEGA2560, 
                Cet outil permettra d'échanger les fichiers binaires avec les mémoires flash et EEPROM du microcontrôleur.</i><br></br>
                <span style={{marginLeft:'30px'}}></span><i>Nous allons voir comment fabriquer la prise de programmation à l'aide d'un Arduino nano qui 
                établira la communication entre notre PC et le bus SPI du microcontrôleur.</i><br></br>
                <span style={{marginLeft:'30px'}}></span><i>Il existe déjà des solutions toutes faites pour cela, l'intérêt ici est de comprendre comment créer ce logiciel  
                et comment utiliser l'outil AVRDUDE.</i><br></br><br></br>
            </p>
            <hr></hr>
            <div style={{textAlign: 'center'}}>
                <span style={{marginLeft:'30px'}}></span><i style={{fontSize: '20px'}}>Sont fournis dans ce document:</i>
                <ul style={{listStyle: 'none'}}>
                    <li><a href={process.env.PUBLIC_URL + "/download/monBoitierAvrFlasher.rar"} target="_blank" rel="noreferrer"><i>Les fichiers 3D de la prise ICSP</i></a></li><br></br>
                    <li><a href={process.env.PUBLIC_URL + "/download/AVR-FLASHER/AVR-FLASHER.zip"} target="_blank" rel="noreferrer"><i>L'exécutable AVR-FLASHER</i></a></li><br></br>
                    <li><a href="https://github.com/PasccalFullStack/AVR-FLASHER" target="_blank" rel="noreferrer" style={{color: 'blue'}}><i>Le lien dépôt public sur GITHUB</i></a></li><br></br>
                </ul>
            </div>
            <hr></hr>
        </div>
        <div className="ttHeader" id="pourquoiutiliserAVRDUDE">
            <h2>Pourquoi apprendre à utiliser AVRDUDE</h2>
        </div>
        <div className="ttText">
            <div className="shadowHorizontalSpace">
                <div className="insertImageLeft">
                    <img
                        src={process.env.PUBLIC_URL + "/images/AVR-FLASHER/avrflasher_img_200x200.png"}
                        alt="Logo de AVR-FLASHER" />
                </div>
                <div>
                    <p className="flash_p">
                        <span style={{marginLeft:'30px'}}></span>Nous sommes nombreux à avoir débuté la programmation embarquée avec 
                        Arduino en profitant d'un IDE sur mesure capable de compiler et de téléverser un programme sans avoir plus de 
                        connaissance sur les outils mis en &#339;uvre en arrière-plan. C'est en s'intéressant à ce côté un peu mystique qu'on découvre 
                        l'outil "<b>AVRDUDE</b>" qui fait tout le travail en arrière plan.<br></br>
                    </p>
                    <p className="flash_p">
                        <span style={{marginLeft:'30px'}}></span>Lorsqu'on développe un code, c'est vraiment pratique de téléverser rapidement 
                        notre programme dans une platine toute faite et facile à connecter en utilisant l'IDE Arduino. Mais lorsque le développement 
                        est fini, on fabrique une carte électronique sur mesure et avec professionnalisme, on flashe la carte par le bus SPI via un 
                        connecteur ICSP. C'est pour cela que savoir manipuler "<b>AVRDUDE</b>" s'avère intéressant.
                    </p>
                </div>
            </div>
                    <p className="flash_p">
                        <span style={{marginLeft:'30px'}}></span>Nous allons démystifier ce principe et créer une application en langage Python 
                        pour flasher aisément nos cartes. Nous irons plus loin en prélevant et en enregistrant la mémoire morte EEPROM et 
                        en créant la fonctionnalité qui permettra de réinjecter celle-ci dans notre microcontrôleur.
                    </p>
            <p className="flash_p">
                <a href="https://wiki.mchobby.be/index.php?title=AVRDUDE" style={{textAlign:'center'}} target="_blank" rel="noreferrer">
                    AVRDUDE est un programme en ligne de commande très populaire pour programmer les puces AVR.
                </a>
            </p>
        </div>
        <div className="ttHeader" id="lesbasespourcomprendre">
            <h2>Quelles bases faut-il avoir pour comprendre ce tutoriel?</h2>
        </div>
        <div className="ttText">
            <p className="flash_p">
                <span style={{marginLeft:'30px'}}></span>Ce tutoriel s'adresse à un public initié sur l'IDE Arduino, sachant écrire un code fonctionnel 
                et régler les paramètres pour le téléverser sur une carte Arduino.<br></br><br></br>
                <span style={{marginLeft:'30px'}}></span>Il sera également nécessaire d'avoir de bonnes bases en langage Python et savoir utiliser l'outil 
                PIP.<br></br><br></br>
                <span style={{marginLeft:'30px'}}></span>Quelques connaissances de la très connue librairie PyQt est un plus.<br></br><br></br>
                <span style={{marginLeft:'30px'}}></span>Enfin, être intéressé pour connaître d'autres méthodes de téléversement d'un code dans un 
                microcontrôleur qu'il soit sur une platine Arduino ou pas.<br></br><br></br>
            </p>
        </div>
        <div className="ttHeader" id="commentfonctionneAVRDUDE">
            <h2>Comment fonctionne AVRDUDE</h2>
        </div>
        <div className="ttText">
            <p className="flash_p">
                <span style={{marginLeft:'30px'}}></span>On peut visualiser la commande AVRDUDE dans la console de l'IDE Arduino en ouvrant 
                le menu Fichier puis Préférences et en cochant l'option: 
                <i> "Afficher la liste de débogage verbeuse pendant le téléversement"</i><br></br>
                Lorsque vous téléverserez votre prochain programme, vous pourrez voir les logs détaillés de AVRDUDE dans la console. 
                C'est à cet endroit que se trouve la commande AVRDUDE qui est executée.
            </p>
            <p className="flash_p">
                <span style={{marginLeft:'30px'}}></span>Voici la commande qui est envoyée par AVRDUDE lorsqu'on téléverse un programme.
                (Détaillée ici en 4 lignes)
            </p>
            <p className="flash_code" style={{background: 'black', color: 'lightblue', paddingLeft: '5px'}}>
                "C:\Users\pascal\AppData\Local\Arduino15\packages\arduino\tools\avrdude\6.3.0-arduino17/bin/avrdude" <br></br>
                "-CC:\Users\pascal\AppData\Local\Arduino15\packages\arduino\tools\avrdude\6.3.0-arduino17/etc/avrdude.conf" <br></br>
                -v -V -patmega2560 -cwiring "-PCOM9" -b115200 -D <br></br>
                "-Uflash:w:C:\Users\pasca\AppData\Local\Temp\arduino\sketches\888DDF43120CDA13FA61FB4916EA4994/Blink.ino.hex:i"
            </p>
            
            <div className="ttHeader">
                <h3>Description de la commande</h3>
            </div>
            <ul className="ttList">
                <li>
                    <span style={{marginLeft:'30px'}}></span><u>Première ligne</u>: La commande sollicite l'exécutable <i><b>avrdude.exe</b> </i> 
                    avec son chemin absolu puisque l'installation de l'IDE Arduino ne prend pas en charge l'inscription de AVRDUDE dans 
                    les variables d'environnement de Windows. 
                </li>
                <li>
                    <span style={{marginLeft:'30px'}}></span><u>Deuxième ligne</u>: La commande sollicite la config <i><b>avrdude.conf</b>, </i> 
                    c'est là que sont répertoriées les données des microcontrôleurs AVR ainsi que les programmeurs disponibles.
                </li>
                <li>
                    <span style={{marginLeft:'30px'}}></span><u>Troisième ligne</u>: On peut lire ici les options de la commande.<br></br>
                    Pour les principales:<br></br> 
                    <span style={{marginLeft:'30px'}}></span>Le microcontrôleur est défini avec l'option "<b>-p</b>" (<b>-patmega2560</b>)<br></br>
                    <span style={{marginLeft:'30px'}}></span>Le programmeur est défini avec l'option "<b>-c</b>" (<b>-cwiring</b>)<br></br>
                    <span style={{marginLeft:'30px'}}></span>Le port sélectionné est défini avec l'option  "<b>-P</b>" (<b>-PCOM9</b>)<br></br>
                    <span style={{marginLeft:'30px'}}></span>La vitesse de transmission est définie avec l'option "<b>-b</b>" (<b>-b115200</b>)<br></br>
                    <span style={{marginLeft:'30px'}}></span>En ce qui concerne les autres options, c'est assez simple à comprendre sur la 
                    <a href="https://www.cs.ou.edu/~fagg/classes/general/atmel/avrdude.pdf" target="_blank" rel="noreferrer"> documentation</a><br></br>
                </li>
                <li>
                    <span style={{marginLeft:'30px'}}></span><u>Dernière ligne</u>: Voici la commande qui provoque la mise à jour de la mémoire flash 
                    de votre microcontrôleur avec la commande: "<b>-Uflash:w:</b>" (w pour write). Ensuite vient le chemin qui va chercher le binaire 
                    précédemment compilé et transcrit en hexadécimal.<br></br>
                    <span style={{color:'#876607'}}><b>La commande utilisée ici (<b>-Uflash:w:</b>) est intéressante, en remplaçant "flash" par "eeprom", on cible la mémoire morte EEPROM plutôt 
                    que la mémoire flash. Et en remplaçant "w" de write par "r" de read, on peut soit lire ou injecter nos binaires. Mais ce n'est pas tout, on 
                    verra d'autres réglages pour arriver à nos fins...</b></span>
                </li>
            </ul>
            <p className="flash_p">
                <span style={{marginLeft:'30px'}}></span>Notez la présence de doubles guillemets qui entourent les chemins, ceci permettent 
                d'exécuter cette commande même si les chemins contiennent des espaces. Les espaces sont un réel problème lorsqu'on envoie des 
                commandes par le cmd de Windows, je vous l'expliquerai un peu plus loin.
            </p>
        </div>
        <div className="ttHeader" id="conceptiondeAVR-FLASHER">
            <h2>Conception de AVR-FLASHER</h2>
        </div>
        <div className="ttText">
            <div className="ttHeader">
                <h3>Pour résumer ce que nous attendons d'un outil de programmation via bus SPI:</h3>
            </div>
            <div className="shadowHorizontalSpace">
                <div>
                    <ul className="ttList2">
                        <li>
                            Sélectionner le port COM sur lequel on a connecté notre carte.
                        </li>
                        <li>
                            Pouvoir lire l'EEPROM et l'enregistrer dans nos dossiers.
                        </li>
                        <li>
                            Pouvoir choisir un fichier binaire de contenu d'EEPROM et l'injecter dans notre microcontrôleur.
                        </li>
                        <li>
                            Pouvoir choisir un fichier binaire de notre programme et l'injecter dans notre microcontrôleur.
                        </li>
                        <li>
                            Créer un executable exploitable sous Windows, et surtout un bel icône...
                        </li>
                    </ul>
                </div>
            </div>
            <div className="ttHeader" id="priseICSP">
                <h3>Conception de la prise ICSP:</h3>
            </div>
            <div className="shadowHorizontalSpace">
                <div className="insertImageLeft">
                    <img
                        src={process.env.PUBLIC_URL + "/images/AVR-FLASHER/arduinoEtSondeAvrFlacher.png"}
                        alt="Carte de développement Arduino mega et la sonde de flash décrite sur cette page"/>
                </div>
                <ul className="ttList2">
                    <li>
                        Nous aurons besoin d'une prise ICSP avec la capacité de transformer notre code envoyé par USB en contenu 
                        transitant par le bus SPI.<br></br>
                        Pour cela, je vais utiliser un Arduino nano programmé avec le code "ArduinoISP.ino" fourni avec l'IDE Arduino.<br></br>
                        Nous créerons un petit boitier pour le contenir équipé d'un connecteur femelle ICSP.<br></br>
                        Les fichiers 3D seront fournis.
                    </li>
                </ul>
            </div>
            <div className="ttHeader">
                <h3>Choix des technos :</h3>
            </div>
            <ul className="ttList2">
                <li>
                    L'ensemble de l'interface sera réalisé sous Python 3.11 avec la librairie PyQt5
                </li>
                <li>
                    Concernant la liaison série, nous utiliserons la librairie "serial" avec l'outil "serial.tools.list_ports" 
                    pour reconnaître les ports COM
                </li>
                <li>
                    Nous utiliserons les librairies "os" et "subprocess". Cette dernière est adaptée à la présence d'espace dans 
                    les chemins lors d'envoi de commandes.
                </li>
                <li>
                    La librairie "pyinstaller" pourra créer l'exécutable avec la commande que je fournis plus bas.
                </li>
            </ul>
        </div>
        <div className="ttHeader" id="titre6">
            <h2>Commençons par la prise ICSP</h2>
        </div>
        <div className="ttText">
            <div className="flash_p">
                <span style={{marginLeft:'30px'}}></span>Pour utiliser l'AVR-FLASHER avec confort, j'ai conçu une prise contenant 
                un Arduino nano. Ce microcontrôleur sera programmé avec <i><b>ArduinoISP.ino</b></i> selon la méthode suivante:
                <ul>
                    <li>
                        Ouvrez votre éditeur Arduino et sélectionnez <i><b style={{color: 'magenta'}}>Fichiers/Exemples/11.ArduinoISP/ArduinoISP </b></i>
                        Comme indiqué ci-dessous.
                        <br></br><br></br>
                        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
                            <img
                            src={process.env.PUBLIC_URL + "/images/AVR-FLASHER/arduinoAsICSPProg.png"}
                            alt="Menu de l'IDE Arduino avec le chemin vers le programme ArduinoISP"
                            style={{width: '75vw', maxWidth: '600px'}}
                            async />
                        </div>
                        <br></br>
                        <span style={{marginLeft:'30px'}}></span>Une fois ce code ouvert et avec l'objectif d'augmenter la vitesse des 
                        téléversements, nous modifierons la vitesse du port série qui est définie à 19200 bauds ligne 142. <br></br>
                        <br></br>
                        <span style={{marginLeft:'30px'}}></span>Je commente cette ligne et décommente la ligne 144 pour définir 
                        la vitesse du port série à 1000000 bauds:<br></br>
                        <br></br>
                        <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-around'}}>
                            <span>Veillez à obtenir le résultat ci-dessous.</span>
                            <img
                                src={process.env.PUBLIC_URL + "/images/AVR-FLASHER/ISP_baudrate.png"}
                                alt="Emplacement du réglage de la vitesse de transmission dans le code ArduinoISP"
                                style={{width: '50vw', maxWidth: '300px'}}
                                async/>
                        </div>
                        <br></br>
                        Aprés avoir vérifié que votre IDE est bien réglé sur une carte nano et avoir sélectionné le port série, nous 
                        téléversons ce code (arduinoISP.ino) dans notre nano par la méthode traditionnelle. 
                        <br></br>
                    </li>
                    <br></br>
                    <li>
                        Voici le boitier que j'ai conçu pour ce tutoriel, il peut contenir un nano et un connecteur en 
                        barrette de pas 2.54mm pour matérialiser la prise ICSP. J'ai réalisé ce boitier avec un détrompeur et 
                        un réceptacle destiné à être fixé sur la 
                        carte cible. Cela assure que cette prise sera toujours connectée dans le bon sens.
                        <div className="shadowImageSpace">
                            <div className="fullImage">
                                <img
                                    src={process.env.PUBLIC_URL + "/images/AVR-FLASHER/boitierSondeVide.png"}
                                    alt="Boitier AVR-FLASHER vide en 3D"
                                    style={{maxWidth: '380px'}}
                                    async />
                            </div>
                            <div className="fullImage">
                                <img
                                    src={process.env.PUBLIC_URL + "/images/AVR-FLASHER/boitierSondeEquipe.png"}
                                    alt="Boitier AVR-FLASHER avec vue sur l'électronique embarquée par transparence"
                                    style={{maxWidth: '380px'}}
                                    async />
                            </div>
                        </div>
                        Veuillez trouver ci-dessous le lien de téléchargement du modèle 3D de ce boitier.
                        <br></br>
                        <a href={process.env.PUBLIC_URL + "/download/monBoitierAvrFlasher.rar"} download>Boitier du AVR-FLASHER</a>
                        <br></br>
                    </li>
                    <br></br>
                    <li>
                        Un petit coup de pouce pour les branchements:<br></br>
                        <div className="shadowImageSpace">
                            <div className="row_flex_aro fullImage_2">
                                <img
                                    src={process.env.PUBLIC_URL + "/images/AVR-FLASHER/nanoBusSPI.png"}
                                    alt="Repéres des branchements du bus SPI sur la carte Arduino nano"
                                    async />
                            </div>
                            <div className="row_flex_aro fullImage_2">
                                <img
                                    src={process.env.PUBLIC_URL + "/images/AVR-FLASHER/nanoVueFilaire.png"}
                                    alt="Vue filaire des branchements sur la carte Arduino nano"
                                    async />
                            </div>
                        </div>
                    </li>                        
                </ul>
            </div>
        </div>
        <div>
            <div className="ttHeader" id="confectionducode">
                <h2>Confection du code Python</h2>
            </div>
            <div className="ttText">
                <p className="flash_p">
                    <span style={{marginLeft:'30px'}}></span>Je vais décrire ici les grandes lignes du code. Vous trouverez le dépôt 
                    GIT en suivant <a href="https://github.com/PasccalFullStack/AVR-FLASHER" target="_blank"  rel="noreferrer">ce lien</a>
                    <br></br>
                    <span style={{marginLeft:'30px'}}></span>A la fin de cette description, vous pourrez télécharger l'exécutable que 
                    j'ai créé.
                    <br></br>
                </p>
                <p className="flash_p">
                    <span style={{marginLeft:'30px'}}></span>Pour commencer ce code, j'importe les librairies choisies plus haut:
                    <br></br>
                </p>
                <div className="dispCode">
                    <code>
                        import sys, os, subprocess, serial<br></br>
                        import serial.tools.list_ports<br></br>
                        from PyQt5.QtCore import *<br></br>
                        from PyQt5.QtWidgets import *<br></br>
                        from PyQt5.uic import loadUi<br></br>
                        from PyQt5 import QtGui<br></br>
                    </code>
                </div>
                <p className="flash_p">
                    <span style={{marginLeft:'30px'}}></span>Je souhaite qu'a l'ouverture de cette application, l'existence du dossier "AVR-SAUVEGARDE" 
                    soit assurée car c'est là que je stockerai mes sauvegardes d'EEPROM.. Je vais donc coder cette condition, si le dossier n'existe pas, il sera créé.
                </p>
                <div className="dispCode">
                    <code>
                        <span style={{marginLeft:'15px', color:'lightgreen'}}># Lecture du user actuel</span><br></br>
                        <span style={{marginLeft:'15px'}}></span>self.username = os.environ.get("USERNAME")<br></br>
                        <span style={{marginLeft:'15px', color:'lightgreen'}}># Confection du path vers ./Documents/</span><br></br>
                        <span style={{marginLeft:'15px'}}></span>self.documents_path = 'C:/Users/' + self.username + '/Documents/'<br></br>
                        <br></br>
                        <span style={{marginLeft:'15px', color:'lightgreen'}}># Control de l'existence de /AVR_SAUVEGARDE/ sinon on le créé</span><br></br>
                        <span style={{marginLeft:'15px'}}></span>if os.path.exists(self.documents_path + 'AVR_SAUVEGARDE') == False:<br></br>
                        <span style={{marginLeft:'30px'}}></span>os.mkdir(os.path.join(self.documents_path, 'AVR_SAUVEGARDE'))<br></br>
                        <br></br>
                        <span style={{marginLeft:'15px', color:'lightgreen'}}># Control de l'existence du fichier eepromsaving.hex sinon on le créé</span><br></br>
                        <span style={{marginLeft:'15px'}}></span>if os.path.exists(self.documents_path + 'AVR_SAUVEGARDE/eepromsaving.hex') == False:<br></br>
                        <span style={{marginLeft:'30px'}}></span>f = open(self.documents_path +<br></br>
                        <span style={{marginLeft:'60px'}}></span>'AVR_SAUVEGARDE/eepromsaving.hex', 'w')<br></br>
                        <span style={{marginLeft:'30px'}}></span>f.write('File is initied')<br></br>
                        <span style={{marginLeft:'30px'}}></span>f.close()<br></br>
                        <br></br>
                    </code>
                </div>
                <p className="flash_p">
                    <span style={{marginLeft:'30px'}}></span>La sélection de la vitesse de transmission est un accessoire, il sera utile au cas ou nous devrions 
                    utiliser une prise réglée autrement qu'à 1 million de Bauds. Une fois le port COM trouvé en appuyant sur "CONNECT", la sélection devra être inactive.
                </p>
                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
                    <img
                        src={process.env.PUBLIC_URL + "/images/AVR-FLASHER/baudSelector.png"}
                        alt="Vue du menu de selection de la vitesse de transmission souhaitée"
                        style={{width: '75vw', maxWidth: '600px'}}
                        async />
                </div>
                <p className="flash_p">
                    <span style={{marginLeft:'30px'}}></span>Ci-dessous le code de ce sélecteur:
                </p>
                <div className="dispCode">
                    <code>
                        <span style={{color:'lightgreen', marginLeft:'15px'}}># Connection des boutons</span><br></br>
                        <span style={{marginLeft:'15px'}}></span>self.set_19200_bauds.clicked.connect(self.define_bauds_19200)<br></br>
                        <span style={{marginLeft:'15px'}}></span>self.set_115200_bauds.clicked.connect(self.define_bauds_115200)<br></br>
                        <span style={{marginLeft:'15px'}}></span>self.set_1000000_bauds.clicked.connect(self.define_bauds_1000000)<br></br>
                        <br></br>
                        <br></br>

                        <span style={{color:'lightgreen', marginLeft:'15px'}}># Fonction qui initialise le style des boutons</span><br></br>
                        <span style={{marginLeft:'15px'}}></span>def init_bauds_buttons (self):<br></br>
                        <span style={{marginLeft:'30px'}}></span>self.set_19200_bauds.setStyleSheet(<br></br>
                        <span style={{marginLeft:'45px'}}></span>'background-color: lightgrey;'<br></br>
                        <span style={{marginLeft:'45px'}}></span>'color: black;'<br></br>
                        <span style={{marginLeft:'45px'}}></span>'font-size: 12px;'<br></br>
                        <span style={{marginLeft:'45px'}}></span>)<br></br>
                        <span style={{marginLeft:'30px'}}></span>self.set_115200_bauds.setStyleSheet(<br></br>
                        <span style={{marginLeft:'45px'}}></span>'background-color: lightgrey;'<br></br>
                        <span style={{marginLeft:'45px'}}></span>'color: black;'<br></br>
                        <span style={{marginLeft:'45px'}}></span>'font-size: 12px;'<br></br>
                        <span style={{marginLeft:'45px'}}></span>)<br></br>
                        <span style={{marginLeft:'30px'}}></span>self.set_1000000_bauds.setStyleSheet(<br></br>
                        <span style={{marginLeft:'45px'}}></span>'background-color: lightgrey;'<br></br>
                        <span style={{marginLeft:'45px'}}></span>'color: black;'<br></br>
                        <span style={{marginLeft:'45px'}}></span>'font-size: 12px;'<br></br>
                        <span style={{marginLeft:'45px'}}></span>)<br></br>
                        <br></br>
                        <span style={{color:'lightgreen', marginLeft:'15px'}}># Sélection: bauds = 19200 bds</span><br></br>
                        <span style={{marginLeft:'15px'}}></span>def define_bauds_19200(self):<br></br>
                        <span style={{marginLeft:'30px'}}></span>self.init_bauds_buttons()<span style={{color:'lightgreen', marginLeft:'15px'}}># Init des 3 boutons</span><br></br>
                        <span style={{marginLeft:'30px'}}></span>self.bauds = "19200"<span style={{color:'lightgreen', marginLeft:'15px'}}># Enregistrement de la valeur choisie</span><br></br>
                        <span style={{marginLeft:'30px'}}></span>self.set_19200_bauds.setStyleSheet(<span style={{color:'lightgreen', marginLeft:'15px'}}># Style du bouton sélectionné</span><br></br>
                        <span style={{marginLeft:'45px'}}></span>'background-color: #70C976;'<br></br>
                        <span style={{marginLeft:'45px'}}></span>'color: black;'<br></br>
                        <span style={{marginLeft:'45px'}}></span>'font-size: 14px;'<br></br>
                        <span style={{marginLeft:'45px'}}></span>)<br></br>
                        <br></br>  
                        <span style={{marginLeft:'15px'}}></span>def define_bauds_115200(self):<br></br>
                        <span style={{marginLeft:'30px'}}></span>self.init_bauds_buttons()<br></br>
                        <span style={{marginLeft:'30px'}}></span>self.bauds = "115200"<br></br>
                        <span style={{marginLeft:'30px'}}></span>self.set_115200_bauds.setStyleSheet(<br></br>
                        <span style={{marginLeft:'45px'}}></span>'background-color: #70C976;'<br></br>
                        <span style={{marginLeft:'45px'}}></span>'color: black;'<br></br>
                        <span style={{marginLeft:'45px'}}></span>'font-size: 14px;'<br></br>
                        <span style={{marginLeft:'45px'}}></span>)<br></br>
                        <br></br>
                        <span style={{marginLeft:'15px'}}></span>def define_bauds_1000000(self):<br></br>
                        <span style={{marginLeft:'30px'}}></span>self.init_bauds_buttons()<br></br>
                        <span style={{marginLeft:'30px'}}></span>self.bauds = "1000000"<br></br>
                        <span style={{marginLeft:'30px'}}></span>self.set_1000000_bauds.setStyleSheet(<br></br>
                        <span style={{marginLeft:'45px'}}></span>'background-color: #70C976;'<br></br>
                        <span style={{marginLeft:'45px'}}></span>'color: black;'<br></br>
                        <span style={{marginLeft:'45px'}}></span>'font-size: 14px;'<br></br>
                        <span style={{marginLeft:'45px'}}></span>)<br></br>
                        <br></br>
                    </code>
                </div>
                <p className="flash_p">
                    <span style={{marginLeft:'30px'}}></span>En ce qui concerne la reconnaissance du port COM, c'est assez simple. Puisque c'est un Arduino nano 
                    qui sera connecté en direct sur le PC, la description du port COM contiendra un de ces 3 termes: "Arduino", "CP210" ou "CH340". 
                    CP210 et CH340 sont les convertisseurs TTL qui équipent les platines Arduino. Il suffit donc de lire le descriptif des ports COM avec 
                    la fonction "<b>serial.tools.list_ports.comports()</b>" et d'y trouver ce qu'on cherche. <br></br>
                    <span style={{marginLeft:'30px'}}></span>Evidemment, cette façon de provoquer une connexion automatique au port 
                    COM trouvé nécessite que <u>un seul Arduino</u> soit connecté sur le PC.
                </p>
                <br></br>
                <div className="dispCode">
                    <code>
                        def set_connection(self):<br></br>
                        <span style={{marginLeft:'15px'}}></span>self.port = 'NONE'<br></br>
                        <span style={{color:'lightgreen', marginLeft:'15px'}}># Lecture des ports COM</span><br></br>
                        <span style={{marginLeft:'15px'}}></span>for p in serial.tools.list_ports.comports():<br></br>
                        <span style={{color:'lightgreen', marginLeft:'30px'}}># Trouver la concordance et incrémenter la table </span><br></br>
                        <span style={{color:'lightgreen', marginLeft:'30px'}}># graphique pour signaler le port COM trouvé</span><br></br>
                        <span style={{marginLeft:'30px'}}></span>if 'Arduino' or 'CP210' or 'CH340' in p.description:<br></br>
                        <span style={{marginLeft:'45px'}}></span>self.port = p.device<br></br>
                        <span style={{marginLeft:'45px'}}></span>self.device.setText(p.device)<br></br>
                        <span style={{marginLeft:'45px'}}></span>self.device.setStyleSheet(<br></br>
                        <span style={{marginLeft:'90px'}}></span>'background-color: lightblue;'<br></br>
                        <span style={{marginLeft:'90px'}}></span>'color: black;'<br></br>
                        <span style={{marginLeft:'90px'}}></span>'font-size: 16px;'<br></br>
                        <span style={{marginLeft:'75px'}}></span>)<br></br>
                        <span style={{color:'lightgreen', marginLeft:'90px'}}># Une fois le port COM défini, on peut désactiver</span><br></br>
                        <span style={{color:'lightgreen', marginLeft:'90px'}}># la sélection de la vitesse de transmission</span><br></br>
                        <span style={{marginLeft:'90px'}}></span>self.set_19200_bauds.setEnabled(False)<br></br>
                        <span style={{marginLeft:'90px'}}></span>self.set_115200_bauds.setEnabled(False)<br></br>
                        <span style={{marginLeft:'90px'}}></span>self.set_1000000_bauds.setEnabled(False)<br></br>
                        <span style={{color:'lightgreen', marginLeft:'15px'}}># Au cas où le port COM n'est pas trouvé, </span><br></br>
                        <span style={{color:'lightgreen', marginLeft:'15px'}}># on le signale sur la table graphique</span><br></br>
                        <span style={{marginLeft:'15px'}}></span>if self.port == 'NONE':<br></br>
                        <span style={{marginLeft:'30px'}}></span>self.device.setText('Not Found!')<br></br>
                        <span style={{marginLeft:'30px'}}></span>self.research_port_button.setStyleSheet(<br></br>
                        <span style={{marginLeft:'90px'}}></span>'background-color: #EC5A24;'<br></br>
                        <span style={{marginLeft:'90px'}}></span>'color: black;'<br></br>
                        <span style={{marginLeft:'90px'}}></span>'font-size: 16px;'<br></br>
                        <span style={{marginLeft:'45px'}}></span>)<br></br>
                        <span style={{color:'lightgreen', marginLeft:'30px'}}># Et on peu réactiver la sélection de vitesse</span><br></br>
                        <span style={{marginLeft:'30px'}}></span>self.set_19200_bauds.setEnabled(True)<br></br>
                        <span style={{marginLeft:'30px'}}></span>self.set_115200_bauds.setEnabled(True)<br></br>
                        <span style={{marginLeft:'30px'}}></span>self.set_1000000_bauds.setEnabled(True)<br></br>
                        <br></br>
                    </code>
                </div>
                <p className="flash_p">
                    <span style={{marginLeft:'30px'}}></span>Ensuite, nous voulons une fonction pour choisir le chemin du dossier sur lequel 
                    les sauvegardes d'EEPROM seront enregistrées. Pour cela, nous pouvons utiliser la fonction "<b>QFileDialog.getExistingDirectory()</b>"
                    fournie par PyQt5. Ainsi, on va provoquer l'ouverture de l'explorateur de fichiers au dossier "AVR-SAUVEGARDE" avec le chemin 
                    précédemment créé.
                </p>
                <div className="dispCode">
                    <code>
                    def save_eeprom_action(self):<br></br>
                    <span style={{marginLeft:'15px'}}></span>if self.port != 'NONE':<br></br>
                    <span style={{marginLeft:'45px'}}></span>savingFolder = str(QFileDialog.getExistingDirectory(self, 'Open File',<br></br>
                    <span style={{marginLeft:'180px'}}></span>self.documents_path + 'AVR_SAUVEGARDE/',<br></br>
                    <span style={{marginLeft:'180px'}}></span>QFileDialog.DontResolveSymlinks))<br></br>
                        <br></br>
                    </code>
                </div>
                <p className="flash_p">
                    <span style={{marginLeft:'30px'}}></span>Une fois le chemin d'enregistrement créé, nous allons concaténer la commande de lecture d'EEPROM
                    et l'exécuter à l'aide de "subprocess". J'ai remarqué que sur certains PC, l'usage de double guillemets si il n'y a pas d'espace dans les 
                    chemins perturbe le fonctionnement, c'est difficilement explicable, mais on ne peut que le constater.
                    <br></br>
                    <span style={{marginLeft:'30px'}}></span>Dans la méthode "__init__", j'ai relevé le chemin de l'installation et je valide un booléen si un 
                    ou plusieurs espaces sont présents dans ce chemin. Par la même occasion, je remplace les backslashs par des slashs dans ce chemin.
                </p>
                <div className="dispCode">
                    <code>
                        <span style={{color:'lightgreen', marginLeft:'15px'}}># Relevé du chemin de l'installation grâce à "os.path.dirname"</span><br></br>
                        <span style={{marginLeft:'15px'}}></span>self.basedir = os.path.dirname(__file__)<br></br>
                        <span style={{marginLeft:'15px'}}></span>newBaseDir = ''<br></br>
                        <span style={{marginLeft:'15px'}}></span>self.spaceInPath = False<br></br>
                        <span style={{color:'lightgreen', marginLeft:'30px'}}># Pour chaque caractère du chemin, vérifier s'il s'agit d'un espace</span><br></br>
                        <span style={{marginLeft:'15px'}}></span>for letter in self.basedir:<br></br>
                        <span style={{marginLeft:'30px'}}></span>if letter == ' ':<br></br>
                        <span style={{color:'lightgreen', marginLeft:'45px'}}># Mémoriser si au moins un espace existe dans le chemin</span><br></br>
                        <span style={{marginLeft:'45px'}}></span>self.spaceInPath = True<br></br>
                        <span style={{color:'lightgreen', marginLeft:'30px'}}># Au cas où il y ai des backslashs, on les remplace par des slashs.</span><br></br>
                        <span style={{marginLeft:'30px'}}></span>if letter == '\\':<br></br>
                        <span style={{marginLeft:'45px'}}></span>letter = '/'<br></br>
                        <span style={{marginLeft:'30px'}}></span>newBaseDir += letter;<br></br>
                        <br></br>
                        <span style={{color:'lightgreen', marginLeft:'30px'}}># Garder en mémoire le chemin retravaillé</span><br></br>
                        <span style={{marginLeft:'15px'}}></span>self.basedir = newBaseDir<br></br>
                        <br></br>
                    </code>
                </div>
                <p className="flash_p">
                    <span style={{marginLeft:'30px'}}></span>Maintenant que ce chemin est créé, on peu concaténer la commande de lecture de l'EEPROM. Vous remarquerez la 
                    partie "<b>-Ueeprom:r:</b>", vous pouvez aussi constater que le programmateur choisi est "stk500v1", ce n'est pas le même qu'utilise l'IDE Arduino pour le 
                    téléversement.
                    <br></br>
                    <span style={{marginLeft:'30px'}}></span>Cette commande sollicite avrdude.exe et avrdude.conf avec le chemin "self.basedir", c'est parce que <b>les 
                    fichiers de avrdude sont présents dans l'installation</b>.
                    <br></br>
                    Pour le reste, on utilise le port connecté avec "self.port", la vitesse de transmission choisie avec "self.bauds" et le chemin choisi pour 
                    l'enregistrement du fichier avec "savingFolder".
                </p>
                <div className="dispCode">
                    <code>
                        <span style={{marginLeft:'15px'}}></span>if self.spaceInPath == True:<br></br>
                        <span style={{color:'lightgreen', marginLeft:'30px'}}># Concaténation si présence d'espace</span><br></br>
                        <span style={{marginLeft:'30px'}}></span>command = '"' + self.basedir + '/avrdude.exe" "-C' + self.basedir + '/avrdude.conf" -c stk500v1 -P ' + \<br></br>
                        <span style={{marginLeft:'45px'}}></span>self.port + ' -patmega2560 -b' + self.bauds + ' "-Ueeprom:r:' + savingFolder + '/eepromsaving.hex:i"'<br></br>
                        <span style={{marginLeft:'15px'}}></span>else:<br></br>
                        <span style={{color:'lightgreen', marginLeft:'30px'}}># Concaténation hors présence d'espace</span><br></br>
                        <span style={{marginLeft:'30px'}}></span>command = self.basedir + '/avrdude.exe -C' + self.basedir + '/avrdude.conf -c stk500v1 -P ' + self.port + \<br></br>
                        <span style={{marginLeft:'45px'}}></span>' -patmega2560 -b' + self.bauds + ' "-Ueeprom:r:' + savingFolder + '/eepromsaving.hex:i"'<br></br>
                        <span style={{color:'lightgreen', marginLeft:'15px'}}># Pour visualiser la commande, utilisez la ligne ci-dessous</span><br></br>
                        <span style={{color:'lightgreen', marginLeft:'15px'}}># print(command)</span><br></br>
                        <br></br>
                        <span style={{color:'lightgreen', marginLeft:'15px'}}># Exécution de la commande grâce à "subprocess", va déclencher l'ouverture du cmd</span><br></br>
                        <span style={{marginLeft:'15px'}}></span>subprocess.run(command)<br></br>
                        <br></br>
                    </code>
                </div>
                <p className="flash_p">
                    <span style={{marginLeft:'30px'}}></span>Après la fin de l'exécution de la commande, on met à jour la table graphique, le message 
                        confirme l'enregistrement, et ce message passe sur fond vert, couleur de validation.
                    <br></br>
                </p>
                <div className="dispCode">
                    <code>
                        <span style={{color:'lightgreen', marginLeft:'15px'}}># Mise à jour du message de confirmation</span><br></br>
                        <span style={{marginLeft:'15px'}}></span>self.save_eeprom.setText('L\'EEPROM est sauvegardée')<br></br>
                        <span style={{color:'lightgreen', marginLeft:'15px'}}># Changement de couleur vers une couleur de validation</span><br></br>
                        <span style={{marginLeft:'15px'}}></span>self.save_eeprom.setStyleSheet(<br></br>
                        <span style={{marginLeft:'45px'}}></span>'background-color: lightgreen;'<br></br>
                        <span style={{marginLeft:'45px'}}></span>'color: black;'<br></br>
                        <span style={{marginLeft:'30px'}}></span>)<br></br>
                        <br></br>
                        <span style={{marginLeft:'15px'}}></span>self.eeprom_saving_ok = True<br></br>
                        <br></br>
                    </code>
                </div>
                <p className="flash_p">
                    <span style={{marginLeft:'30px'}}></span>Cette méthode décrite ci-dessus est semblable a celles qui permettent de commander 
                    l'écriture de la mémoire flash ou de l'EEPROM, la différence réside dans le choix du fichier.hex qui sera chargé.<br></br>
                    <span style={{marginLeft:'30px'}}></span>Je vais donc décrire ici la méthode qui permet de sélectionner un fichier ou plutôt un chemin de fichier et de contrôler 
                    que l'extension est bien "<b>.hex</b>".<br></br>
                    <span style={{marginLeft:'30px'}}></span>La probabilité d'avoir le fichier concerné dans son dossier "Téléchargements" est 
                    forte, c'est pourquoi je vais créer un chemin vers le dossier de téléchargement. J'utilise ensuite la méthode 
                    "<b>QFileDialog.getOpenFileName()</b>" fournie par PyQt5 pour ouvrir l'explorateur vers un choix de fichier.
                    <br></br>
                </p>
                <div className="dispCode">
                    <code>
                        <span style={{marginLeft:'15px'}}></span>def get_hex_file(self):<br></br>
                        <span style={{color:'lightgreen', marginLeft:'30px'}}># Création d'un path vers /Downloads/</span><br></br>
                        <span style={{marginLeft:'30px'}}></span>hex_path = 'C:/Users/' + self.username + '/Downloads/'<br></br>
                        <span style={{marginLeft:'30px'}}></span>self.filename = ''<br></br>
                        <span style={{color:'lightgreen', marginLeft:'30px'}}># Ouverture de l'explorateur</span><br></br>
                        <span style={{marginLeft:'30px'}}></span>self.getHexFile = QFileDialog.getOpenFileName(<br></br>
                        <span style={{marginLeft:'60px'}}></span>self,<br></br>
                        <span style={{marginLeft:'60px'}}></span>"Open file",<br></br>
                        <span style={{marginLeft:'60px'}}></span>hex_path,<br></br>
                        <span style={{marginLeft:'60px'}}></span>".hex (*)",<br></br>
                        <span style={{marginLeft:'45px'}}></span>)<br></br>
                        <br></br>
                        <span style={{color:'lightgreen', marginLeft:'30px'}}># Control de l'extension du fichier sélectionné (.hex)</span><br></br>
                        <span style={{marginLeft:'30px'}}></span>if self.getHexFile and self.getHexFile[0][-4:] == '.hex':<br></br>
                        <span style={{color:'lightgreen', marginLeft:'30px'}}># Isolation du nom du fichier depuis le path de chargement</span><br></br>
                        <span style={{marginLeft:'45px'}}></span>self.filename = self.getHexFile[0].split('/')[-1]<br></br>
                        <span style={{color:'lightgreen', marginLeft:'30px'}}># Mise à jour de la table graphique</span><br></br>
                        <span style={{marginLeft:'45px'}}></span>self.loading_filename.setText(self.filename)<br></br>
                        <span style={{marginLeft:'45px'}}></span>self.loading_filename.setStyleSheet(<br></br>
                        <span style={{marginLeft:'75px'}}></span>'background-color: #70C976;'<br></br>
                        <span style={{marginLeft:'75px'}}></span>'color: black;'<br></br>
                        <span style={{marginLeft:'75px'}}></span>'font-size: 16px;'<br></br>
                        <span style={{marginLeft:'60px'}}></span>)<br></br>
                        <span style={{color:'lightgreen', marginLeft:'30px'}}># Signalement d'erreur si le fichier choisi n'est pas un .hex</span><br></br>
                        <span style={{marginLeft:'30px'}}></span>else:<br></br>
                        <span style={{marginLeft:'45px'}}></span>self.filename = ''<br></br>
                        <span style={{marginLeft:'45px'}}></span>self.button_open_file.setText('Uniquement un fichier .hex')<br></br>
                        <span style={{marginLeft:'45px'}}></span>self.loading_filename.setText(<br></br>
                        <span style={{marginLeft:'60px'}}></span>'Erreur, uniquement un fichier .hex ...')<br></br>
                        <br></br>
                    </code>
                </div>
                <p className="flash_p">
                    <span style={{marginLeft:'30px'}}></span>A ce niveau, le chemin du fichier.hex est sélectionné et il ne reste qu'à générer la 
                    commande AVRDUDE de la même façon que la lecture de l'EEPROM a été réalisée.<br></br>
                    <span style={{marginLeft:'30px'}}></span>Il en sera de même pour l'injection d'EEPROM, la méthode de sélection d'un fichier.hex 
                    précédera la création de la commande et l'exécution avec "subprocess".<br></br>
                    <span style={{marginLeft:'30px'}}></span>Je vous laisse consulter le dépôt <a href="https://github.com/PasccalFullStack/AVR-FLASHER" target="_blank" rel="noreferrer"><b>GITHUB</b></a>, 
                    les commentaires que j'y ai laissés sont explicites.<br></br>
                    <br></br>
                </p>
            </div>
        </div>
        <div>
            <div className="ttHeader" id="creationdelexecutable">
                <h2>Création de l'exécutable</h2>
            </div>
            <div className="ttText">
                <p className="flash_p">
                    <span style={{marginLeft:'30px'}}></span>Pour que cette application soit autonome, il faut ajouter à la racine les fichiers de service de AVRDUDE. 
                    Vous les trouverez au bout de ce path: <br></br>
                    <b>C:/Users/<span style={{color:'brown'}}>username</span>/AppData/Local/Arduino15/packages/arduino/tools/avrdude/6.3.0-arduino17/</b><br></br>
                    Si votre 
                    installation est comme la mienne. Au bout de ce path, il y a 2 dossiers, "<b>etc</b>" et "<b>bin</b>", j'ai simplement copié les fichiers de ces 
                    2 dossiers et collé l'ensemble à la racine de mon projet. Ci-dessous la liste des fichiers à copier/coller:<br></br>
                </p>
                <div style={{textAlign: 'center'}}>
                    <ul style={{listStyle: 'none'}}>
                        <b>
                        <li>avrdude.conf</li>
                        <li>avrdude.exe</li>
                        <li>libusb0.dll</li>
                        <li>loaddrv.exe</li>
                        <li>giveio.sys</li>
                        <li>install_giveio.bat</li>
                        <li>remove_giveio.bat</li>
                        <li>status_giveio.bat</li>
                        </b>
                    </ul>
                </div>
                <p className="flash_p">
                    <span style={{marginLeft:'30px'}}></span>Pour créer l'exécutable, j'utilise "pyinstaller", n'hésitez pas à l'installer avec "PIP".<br></br>
                    <span style={{marginLeft:'30px'}}></span>Voici maintenant la commande "pyinstaller" permettant de créer l'exécutable:<br></br>
                    <br></br>
                </p>
                <div className="dispCode">
                    <code>
                    pyinstaller --noconsole --noconfirm --icon favicon.ico --name "AVR-FLASHER" --add-data="favicon.ico;." --add-data="flasherUI.ui;." 
                    --add-data="avrdude.exe;." --add-data="giveio.sys;." --add-data="install_giveio.bat;." --add-data="libusb0.dll;." --add-data="loaddrv.exe;." 
                    --add-data="remove_giveio.bat;." --add-data="status_giveio.bat;." --add-data="avrdude.conf;." main.py
                    </code>
                </div>
                <p className="flash_p">
                    <span style={{marginLeft:'30px'}}></span>Vous pouvez l'exécuter dans la console à la racine de votre projet, vérifiez bien que tous les fichiers soient présents.<br></br>
                    Et n'oubliez pas votre icon... (favicon.ico)<br></br>
                    <span style={{marginLeft:'30px'}}></span>Libre à vous de créer un installateur Windows, j'ai utilisé 
                    <span> </span><a href="https://jrsoftware.org/isinfo.php" target="_blank" rel="noreferrer">Inno Setup</a> pour créer l'exécutable ci-dessous, 
                    <span> </span><a href="https://installforge.net/" target="_blank" rel="noreferrer">InstallForge</a> est facile à prendre en main, il en existe d'autres.
                </p>
                <br></br>
                <p className="flash_p" style={{border: '1px solid blue', borderRadius: '10px', background: 'rgba(141, 234, 223, 0.2)',textAlign: 'center', fontSize: '16px'}}>
                    Veuillez trouver ci-dessous le setup de AVR-FLASHER que j'ai construit avec Inno Setup.<br></br>
                    <span> <a href={process.env.PUBLIC_URL + "/download/AVR-FLASHER/AVR-FLASHER.zip"} target="_blank" rel="noreferrer"><b>AVR-FLASHER.zip</b></a></span><br></br>
                    Après l'avoir décompressé, vous pourrez l'installer, ne soyez pas surpris si Defender émet une alerte, c'est qu'il travaille bien…
                </p>
                <br></br>
            </div>
        </div>
        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
            <img
                src={process.env.PUBLIC_URL + "/images/AVR-FLASHER/AVR_produit_fini.png"}
                alt="Sonde de programmation finie et prête à servir"
                style={{width: '75vw', maxWidth: '400px'}}
                async />
        </div>
        <div>
            <div className="ttHeader" id="utilisationdeavrflasher">
                <h2>Utiliser AVR-FLASHER</h2>
            </div>
            <div className="ttText">
                <p className="flash_p">
                    <span style={{marginLeft:'30px'}}></span>Pour tester AVR-FLASHER, on peut simplement connecter notre prise au bus SPI d'une platine 
                    Arduino MEGA.
                </p>
                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
                    <img
                        src={process.env.PUBLIC_URL + "/images/AVR-FLASHER/connexionSPI.png"}
                        alt="Sonde AVR-FLASHER connectée sur le bus SPI d'une carte Arduino mega"
                        style={{width: '50vw', maxWidth: '300px'}}
                        async />
                </div>
                <p className="flash_p">
                    <span style={{marginLeft:'30px'}}></span>Après avoir installé AVR-FLASHER, ouvrez le et découvrez l'interface graphique. 
                    La vitesse de communication est prédéfinie à 1 million de Bauds, cliquez sur "CONNECT" et vérifiez que le port COM s'affiche, ici le port COM17.
                </p>
                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
                    <img
                        src={process.env.PUBLIC_URL + "/images/AVR-FLASHER/userInterface.png"}
                        alt="Interface utilisateur du logiciel créé"
                        style={{width: '50vw', maxWidth: '300px'}}
                        async />
                </div>
                <p className="flash_p">
                    <span style={{marginLeft:'30px'}}></span>Lorsque la connexion est établie, le bouton "<b>1 - Prélevez la configuration actuelle</b>" devient actif.
                    On peut alors lire le contenu de l'EEPROM. Ici, ce bouton est en vert car l'EEPROM à déjà été lue.<br></br>
                    <span style={{marginLeft:'30px', color: "brown"}}><b>Astuce: pour convertir un programme en binaire avec l'IDE Arduino, ouvrez le menu croquis et cliquez sur 
                    "Exporter les binaires compilés". Vous trouverez votre fichier.hex en cliquant sur "Ouvrir le dossier de croquis". Rendez vous dans "/build/arduino.avr.mega/" 
                    et sélectionnez le .hex sans bootloader.</b></span>
                    <br></br>
                    <span style={{marginLeft:'30px'}}></span>Après avoir chargé un binaire sous extension .hex, le bouton "<b>2 - Mettre à jour votre projet</b>" devient actif.<br></br>
                    <span style={{marginLeft:'30px'}}></span>Le bouton "<b>3 - Injecté votre configuration</b>" est inactif, c'est parce que aucun fichier d'EEPROM 
                    n'a été sélectionné. 
                </p>
                <p className="flash_p">
                    <span style={{marginLeft:'30px'}}></span>Pendant la lecture ou l'écriture d'une mémoire, le cmd s'ouvre et affiche les retours de console de AVRDUDE. 
                    Une action AVRDUDE se termine bien lorsque le message "Thank you" apparaît.
                </p>
                <p className="flash_p">
                    <span style={{marginLeft:'30px'}}></span>L'écriture d'un fichier EEPROM est longue, supérieure à 3 minutes. En effet, l'ATMEGA2560 dispose d'e 4096 octets 
                    d'EEPROM et l'écriture d'un octet peut prendre jusqu'a 40 millisecondes.
                </p>
                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
                    <img
                        src={process.env.PUBLIC_URL + "/images/AVR-FLASHER/AVRcmd.png"}
                        alt="Vue de la console pendant le travail de l'outil AVRDUDE"
                        style={{width: '60vw', maxWidth: '600px'}}
                        async />
                </div>
            </div>
        </div>
        <br></br><br></br><br></br>
        <div>
            <div className="ttHeader" id="evolutionspossibles">
                <h2>Evolutions possibles de cette application</h2>
            </div>
            <div className="ttText">
                <p className="flash_p">
                    <span style={{marginLeft:'30px'}}></span>Cette application est évolutive, de nombreuses fonctionnalités pourraient s'y ajouter.<br></br>
                </p>
            </div>
            <div style={{margin: '0 10vw'}}>
                <ul>
                    <li>Pouvoir sélectionner un type de microcontrôleur et un type de programmeur<br></br>
                        <span style={{fontSize: '12px'}}>
                            <i>En effet, AVRDUDE est conçu pour fonctionner avec beaucoup de microcontrôleurs et de programmeurs, 
                            nous n'avons utilisé ici que l'ATMEGA2560</i>
                        </span>
                    </li>
                    <br></br>
                    <li>Lire un binaire dans la mémoire flash<br></br>
                        <span style={{fontSize: '12px'}}>
                            <i>Si vous avez bien compris le fonctionnement d'une commande AVRDUDE, il vous sera facile de coder une 
                            fonctionnalité de lecture d'un binaire depuis une mémoire flash</i>
                        </span>
                    </li>
                    <br></br>
                    <li>Intégrer les retours de console dans l'application plutôt que dans le cmd<br></br>
                        <span style={{fontSize: '12px'}}>
                            <i>Il faut l'avouer, déclencher l'ouverture du cmd par-dessus un logiciel, c'est du vite fait. On pourrait 
                            facilement récupérer les retours de console et les intégrer dans le user interface...</i>
                        </span>
                    </li>
                    <br></br>
                    <li>Intégrer des témoins de progression pendant les lectures et écritures.<br></br>
                        <span style={{fontSize: '12px'}}>
                            <i>Ce serait sympa de visualiser la progression d'un flash avec une progressBar, et l'affichage dynamique du 
                            pourcentage de progression</i>
                        </span>
                    </li>
                    <br></br>
                    <li>Créer une fonctionnalité de choix de langues<br></br>
                        <span style={{fontSize: '12px'}}>
                            <i>Un logiciel moderne doit être proposé en plusieurs langues</i>
                        </span>
                    </li>
                    <br></br>
                </ul>
            </div>
            <div className="ttHeader">
                <h3>A vos claviers...</h3>
            </div>
                <br></br><br></br><br></br><br></br><br></br><br></br>
        </div>
        <div className="AVRmenu">
            <span className="arrowMenu">
                <ion-icon name="arrow-redo-outline"></ion-icon>
            </span>
            <div className="showAVRMenu">
                <ul>
                    <li style={{textAlign: 'center', paddingRight: '35px'}}><b>SOMMAIRE</b></li>
                    <hr style={{width: '50px', position: 'relative', right: '20px'}}></hr>
                    <li>
                        <a
                            href="#/AVR-FLASHER#questcequecestAVR-FLASHER"
                            className="AVRMenuItem"
                            onClick={() => {
                                document.querySelector('#questcequecestAVR-FLASHER').scrollIntoView({
                                        block: 'start',
                                        behavior: 'smooth',
                                        inline: 'nearest'
                                    });
                            }}>
                                Qu'est-ce que AVR-FLASHER
                        </a>
                    </li>
                    <br></br>
                    <li>
                        <a 
                            href="#/AVR-FLASHER/#pourquoiutiliserAVRDUDE" 
                            className="AVRMenuItem"
                            onClick={() => {
                                document.querySelector('#pourquoiutiliserAVRDUDE').scrollIntoView({
                                        block: 'start',
                                        behavior: 'smooth',
                                        inline: 'nearest'
                                    });
                            }}>
                                Pourquoi apprendre à utiliser AVRDUDE
                        </a>
                    </li>
                    <br></br>
                    <li>
                        <a
                            href="#/AVR-FLASHER#lesbasespourcomprendre"
                            className="AVRMenuItem"
                            onClick={() => {
                                document.querySelector('#lesbasespourcomprendre').scrollIntoView({
                                        block: 'start',
                                        behavior: 'smooth',
                                        inline: 'nearest'
                                    });
                            }}>
                                Quelles bases faut-il avoir pour comprendre ce tutoriel?
                        </a>
                    </li>
                    <br></br>
                    <li>
                        <a
                            href="#/AVR-FLASHER#commentfonctionneAVRDUDE"
                            className="AVRMenuItem"
                            onClick={() => {
                                document.querySelector('#commentfonctionneAVRDUDE').scrollIntoView({
                                        block: 'start',
                                        behavior: 'smooth',
                                        inline: 'nearest'
                                    });
                            }}>
                                Comment fonctionne AVRDUDE
                        </a>
                    </li>
                    <br></br>
                    <li>
                        <a
                            href="#/AVR-FLASHER#conceptiondeAVR-FLASHER"
                            className="AVRMenuItem"
                            onClick={() => {
                                document.querySelector('#conceptiondeAVR-FLASHER').scrollIntoView({
                                        block: 'start',
                                        behavior: 'smooth',
                                        inline: 'nearest'
                                    });
                            }}>
                            Conception de AVR-FLASHER
                        </a>
                    </li>
                    <br></br>
                    <li>
                        <a
                            href="#/AVR-FLASHER#priseICSP"
                            className="AVRMenuItem"
                            onClick={() => {
                                document.querySelector('#priseICSP').scrollIntoView({
                                        block: 'start',
                                        behavior: 'smooth',
                                        inline: 'nearest'
                                    });
                            }}>
                                Commençons par la prise ICSP
                        </a>
                    </li>
                    <br></br>
                    <li>
                        <a
                            href="#/AVR-FLASHER#confectionducode"
                            className="AVRMenuItem"
                            onClick={() => {
                                document.querySelector('#confectionducode').scrollIntoView({
                                        block: 'start',
                                        behavior: 'smooth',
                                        inline: 'nearest'
                                    });
                            }}>
                                Confection du code Python
                        </a>
                    </li>
                    <br></br>
                    <li>
                        <a
                            href="#/AVR-FLASHER#creationdelexecutable"
                            className="AVRMenuItem"
                            onClick={() => {
                                document.querySelector('#creationdelexecutable').scrollIntoView({
                                        block: 'start',
                                        behavior: 'smooth',
                                        inline: 'nearest'
                                    });
                            }}>
                                Création de l'exécutable
                        </a>
                    </li>
                    <br></br>
                    <li>
                        <a
                            href="#/AVR-FLASHER#utilisationdeavrflasher"
                            className="AVRMenuItem"
                            onClick={() => {
                                document.querySelector('#utilisationdeavrflasher').scrollIntoView({
                                        block: 'start',
                                        behavior: 'smooth',
                                        inline: 'nearest'
                                    });
                            }}>
                                Utiliser AVR-FLASHER
                        </a>
                    </li>
                    <br></br>
                    <li>
                        <a
                            href="#/AVR-FLASHER#evolutionspossibles"
                            className="AVRMenuItem"
                            onClick={() => {
                                document.querySelector('#evolutionspossibles').scrollIntoView({
                                        block: 'start',
                                        behavior: 'smooth',
                                        inline: 'nearest'
                                    });
                            }}>
                                Evolutions possibles de cette application
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </section>
  )
}
