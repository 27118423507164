import React, {useEffect, useReducer} from 'react';
import {Link} from 'react-router-dom';
import belevenementReducer from './belevenementReducer';
import changeBltImg from './changeBltImg';
import sendNavigationInfo from '../functions/sendNavigationInfo';
import './belevenementStyle.css';

export default function BelevenementPage() {
  const bltUrl = "https://www.belevenement.fr";
  const initialState = ({
    adminMode: false,
    image: 'compoDynImgModel.jpg',
    image_pos_x: 0,
    image_pos_y: 0,
    image_zoom: 1,
    message: '',
    card_bg: '#ccd4e5',
    text_color: '#0a0245',
    text_mode: '2',
    image_place: 'left',
    title: 'Démonstration',
    text_1: 'Ce composant dynamique est paramétrable, changez les textes, l\'image et les couleurs selon vos souhaits.',
    text_2: 'Vous pouvez centrer l\'image en cliquant dessus et régler le zoom. Choisissez aussi de quel coté vous préférez l\'image.',
    ps: 'PS : Attention au nombre de caractères disponibles.',
  })
  const [state, dispatch] = useReducer(belevenementReducer, initialState); 
  const imageEvent = () => {
  var container = document.querySelector('.formuleImageContainer');
  if (document.querySelector('.formuleImageContainer')) {
    container.addEventListener('click', (e) => {
        const rect = container.getBoundingClientRect();
        const x = e.clientX - 150 - rect.left;
        const y = e.clientY - 150 - rect.top;
        const xPercent = (x / rect.width) * 100;
        const yPercent = (y / rect.height) * 100;
        // console.log(xPercent, yPercent)
        dispatch({
          type: 'change_image_position',
          payload:{
            image_pos_x: xPercent,
            image_pos_y: yPercent,
          }})
      });
    }
  }
  useEffect(() => {
    imageEvent();

    return;
  }, [])
  
  return (
    <section className="col_flex_aro AVRsection">
      <div className="centrCenter bltPubContainer" style={{position: 'relative'}}>
        <a className="bltwebappLink" href={bltUrl} target="_blank" rel="noreferrer">
            www.belevenement.fr
        </a>
        {window.screen.width > 600 && (
          <img
            src={process.env.PUBLIC_URL + '/images/belevenement/belevenement_120x120.png'}
            style={{marginLeft: '10px'}}
            alt="Logo de Belévénement" />
        )}
        <div className="centrCenterColumn bltPubContent">
          <h3 style={{margin: '5px', padding: '0', fontSize: 'var(--bltPubH3FontSize)'}}>
            {window.screen.width < 600 && (
              <img
                src={process.env.PUBLIC_URL + '/images/belevenement/belevenement_img_60x60.png'}
                style={{position: 'relative', top: '20px'}}
                alt="Logo de Belévénement" />
            )}
            Organisation événementielle
          </h3>
          <h4 style={{fontSize: 'var(--bltPubH4FontSize)'}}>Mariages - Voyages - Autres événements</h4>
          <p style={{margin: '0 5px 0 0', padding: '5px'}}>
            <span style={{marginLeft: '30px'}}></span>
            Je suis là pour vous aider à chaque étape en m'assurant que tout se déroule sans 
            accroc et que vous puissiez profiter pleinement de chaque moment. Que ce soit pour 
            un voyage de rêve, une célébration spéciale ou un événement professionnel, 
            je suis à votre disposition pour transformer vos idées en réalité.
          </p>
          <p style={{margin: '0 5px 0 0', padding: '5px'}}>
            <span style={{marginLeft: '30px'}}></span>
            Contactez-moi dès aujourd'hui et 
            laissez-moi m'occuper de tout, afin que vous puissiez vous détendre et savourer 
            chaque instant.
          </p>
          <p style={{marginLeft: '20vw'}}>
            <span style={{marginLeft: '30px'}}></span>
            <a href={bltUrl + "/#/Contact"} target="_blank" rel="noreferrer">
            <img
              className="bltContactLink"
              src={process.env.PUBLIC_URL + '/images/belevenement/contactLink.png'}
              alt="Contactez Bélévénement" />
            </a>
            <i>Bélen Rodriguez</i>
          </p>
        </div>
      </div>
      <div  className="ttHeader" id="site_autonome" style={{position: 'relative', left: '-50px'}}>
          <h2>Quest-ce qu'un site autonome</h2>
      </div>
      <div className="ttText">
          <p className="flash_p">
              <span style={{marginLeft:'30px'}}></span>
              Le site www.belevenement.fr a été conçut pour offrir à la propriétaire de l'entreprise un 
              contrôle complet sur la gestion des contenus. Plus de 90% des textes et images sont facilements 
              interchangeables avec une connexion en mode administrateur et des outils ergonomiques. 
              <br></br><br></br>
              <span style={{marginLeft:'30px'}}></span>
              La propriétaire peut ainsi librement changer ses contenus sans dépendre d'un développeur et 
              sans avoir besoin de connaissances du domaine numérique. Le coût de fonctionnement est de 
              ce fait réduit, car il n'y a pas de contrat de maintenance imposant un nombre de modification 
              limité chaque mois. Le référencement naturel d'un site tient compte de la régularité des mises à 
              jours des contenus. La possibilité de gérer ses contenus en toute indépendance est donc un réél avantage.
              <br></br><br></br>
          </p>
      </div>
      <div className="ttHeader" id="exemple_de_composant_parametrable" style={{position: 'relative', left: '-50px'}}>
          <h2>Comment ça fonctionne?</h2>
      </div>
      <div className="ttText">
          <p className="flash_p">
              <span style={{marginLeft:'30px'}}></span>
              Ci-dessous un exemple de composant administrable avec son outil de gestion, vous pouvez l'essayer, inclure vos 
              textes, vos images, choisir vos couleurs. Voilà l'ergonomie dont dispose un administrateur pour mettre 
              à jour ses contenus.
              <br></br><br></br>
              <span style={{marginLeft:'30px'}}></span>
              Sur ce modèle de démonstration interactif, vos données ne seront pas enregistrées. Commencez par switcher 
              entre les modes administrateurs et utilisteurs en utilisant le bouton ci-dessous. Vous pourrez ensuite utiliser 
              les éléments de contrôle qui apparaîtrons en dessous de la carte.
              <br></br><br></br>
          </p>
      </div>
      {window.screen.width >= 300 && (   
        <div className="row_flex_aro">
          <input
            className="bltCheckbox"
            onChange={() => dispatch({type: 'change_mode', payload: ({adminMode: !state.adminMode})})}
            type="checkbox"
            name="adminMode"/>
            <div className="col_flex_aro adminModeBut noselect" style={{minWidth: '140px'}}>
              <p>Mode :</p>
              <p>{state.adminMode ? 'Administrateur' : 'Utilisateur'}</p>
            </div>
        </div>
      )}  
      {window.screen.width < 650 && (
        <div className="col_flex_aro formuleContainerSmall" style={{background: state.card_bg}}>
          {state.image_place === 'left' && (
            <div
              className="row_flex_aro formuleImageContainer noselect"
              style={{position: 'relative'}}>
              <img
                className="formuleImage"
                style={{
                  transform:
                    'translateX(' + state.image_pos_x + '%) translateY(' + state.image_pos_y + '%) scale(' + state.image_zoom +')',
                }}
                src={process.env.REACT_APP_BLT_IMAGE_URL + state.image}
                alt="représentation de la carte"/>
                {!['', 'Le fichier est enregistré.'].includes(state.message) && (
                  <span className="imgLoadingMessage">
                    {state.message}
                  </span>
                )}
            </div>
          )}
          {state.adminMode ? (
            <div className="col_flex_aro textContainer noselect">
              <div className="col_flex_aro">
                <label
                  className="formuleLabelStyle"
                  style={{color: state.title.length >= 20 ? 'red' : state.text_color}}>
                    {state.title.length + ' / 20 caractères'}
                </label>
                <textarea
                  className="textAreaStyle formuleTitleTextAreaStyle"
                  id="titleArea"
                  style={{background: state.card_bg, color: state.text_color}}
                  rows="1"
                  cols="20"
                  onFocus={() => {
                    document.querySelector('#titleArea').style.background = 'lightyellow';
                    document.querySelector('#titleArea').style.color = 'black';
                  }}
                  onBlur={() => {
                    document.querySelector('#titleArea').style.background = state.card_bg;
                    document.querySelector('#titleArea').style.color = state.text_color;
                  }}
                  onChange={(e) => dispatch({type: 'change_title', payload: {title: e.target.value}})}
                  value={state.title}></textarea>
              </div>
              <div className="col_flex_aro">
                <label
                  className="formuleLabelStyle"
                  style={{color: 
                    ((state.text_mode === '2' && state.text_1.length >= 123)
                    ||
                    (state.text_mode === '1' && state.text_1.length >= 256))
                      ? 'red'
                      : state.text_color,
                  }}>
                  {state.text_mode === '2' && (<span>{state.text_1.length + ' / 123 caractères'}</span>)}
                  {state.text_mode === '1' && (<span>{state.text_1.length + ' / 256 caractères'}</span>)}
                </label>
                <textarea
                  className="textAreaStyle formuleTextTextAreaStyle"
                  id="text_1Area"
                  style={{background: state.card_bg, color: state.text_color}}
                  onFocus={() => {
                    document.querySelector('#text_1Area').style.background = 'lightyellow';
                    document.querySelector('#text_1Area').style.color = 'black';
                  }}
                  onBlur={() => {
                    document.querySelector('#text_1Area').style.background = state.card_bg;
                    document.querySelector('#text_1Area').style.color = state.text_color;
                  }}
                  rows={state.text_mode === '2' ? '4' : '8'}
                  cols="32"
                  onChange={(e) => dispatch({type: 'change_text_1', payload: {text_1: e.target.value}})}
                  value={state.text_1}></textarea>
              </div>
              {state.text_mode === '2' && (
                <div className="col_flex_aro">
                  <label
                    className="formuleLabelStyle"
                    style={{color: state.text_2.length >= 123 ? 'red' : state.text_color}}>
                      {state.text_2.length + ' / 123 caractères'}
                  </label>
                  <textarea
                    className="textAreaStyle formuleTextTextAreaStyle"
                    id="text_2Area"
                    style={{background: state.card_bg, color: state.text_color}}
                    onFocus={() => {
                      document.querySelector('#text_2Area').style.background = 'lightyellow';
                      document.querySelector('#text_2Area').style.color = 'black';
                    }}
                    onBlur={() => {
                      document.querySelector('#text_2Area').style.background = state.card_bg;
                      document.querySelector('#text_1Area').style.color = state.text_color;
                    }}
                    rows="4"
                    cols="32"
                    onChange={(e) => dispatch({type: 'change_text_2', payload: {text_2: e.target.value}})}
                    value={state.text_2}></textarea>
                </div>
              )}
              <div className="col_flex_aro">
                <label
                  className="formuleLabelStyle"
                  style={{color: state.ps.length >= 62 ? 'red' : state.text_color}}>
                    {state.ps.length + ' / 62 caractères'}
                </label>
                <textarea
                  className="textAreaStyle formuleTextTextAreaStyle"
                  id="psArea"
                  style={{background: state.card_bg, color: state.text_color}}
                  onFocus={() => {
                    document.querySelector('#psArea').style.background = 'lightyellow';
                    document.querySelector('#psArea').style.color = 'black';
                  }}
                  onBlur={() => {
                    document.querySelector('#psArea').style.background = state.card_bg;
                    document.querySelector('#psArea').style.color = state.text_color;
                  }}
                  rows="2"
                  cols="32"
                  onChange={(e) => dispatch({type: 'change_ps', payload: {ps: e.target.value}})}
                  value={state.ps}></textarea>
              </div>
            </div>
          ) : (
            <div className="col_flex_aro textContainer noselect">
              <div className="formuleTitleStyle" style={{color: state.text_color}}>
                {state.title}
              </div>
              <div className="formuleTextStyle" style={{color: state.text_color}}>
                <span style={{marginLeft: '30px'}}></span>
                {state.text_1}
              </div>
              <div className="formuleTextStyle" style={{color: state.text_color}}>
                <span style={{marginLeft: '30px'}}></span>
                {state.text_2}
              </div>
              <div className="formulePostStyle" style={{color: state.text_color}}>
                <span style={{marginLeft: '30px'}}></span>
                {state.ps}
              </div>
            </div>
          )}
          {state.image_place === 'right' && (
            <div
              className="row_flex_aro formuleImageContainer noselect"
              style={{position: 'relative'}}>
              <img
                className="formuleImage"
                style={{
                  transform:
                    'translateX(' + state.image_pos_x + '%) translateY(' + state.image_pos_y + '%) scale(' + state.image_zoom +')',
                }}
                src={process.env.REACT_APP_BLT_IMAGE_URL + state.image}
                alt="représentation de la carte"/>
                {!['', 'Le fichier est enregistré.'].includes(state.message) && (
                  <span className="imgLoadingMessage">
                    {state.message}
                  </span>
                )}
            </div>
          )}
        </div>
      )}
      {window.screen.width >= 650 && (
        <div className="row_flex_aro formuleContainer" style={{background: state.card_bg}}>
          {state.image_place === 'left' && (
            <div
              className="row_flex_aro formuleImageContainer noselect"
              style={{position: 'relative'}}>
              <img
                className="formuleImage"
                style={{
                  transform:
                    'translateX(' + state.image_pos_x + '%) translateY(' + state.image_pos_y + '%) scale(' + state.image_zoom +')',
                }}
                src={process.env.REACT_APP_BLT_IMAGE_URL + state.image}
                alt="représentation de la carte"/>
                {!['', 'Le fichier est enregistré.'].includes(state.message) && (
                  <span className="imgLoadingMessage">
                    {state.message}
                  </span>
                )}
            </div>
          )}
          {state.adminMode ? (
            <div className="col_flex_aro textContainer noselect">
              <div className="col_flex_aro">
                <label
                  className="formuleLabelStyle"
                  style={{color: state.title.length >= 20 ? 'red' : state.text_color}}>
                    {state.title.length + ' / 20 caractères'}
                </label>
                <textarea
                  className="textAreaStyle formuleTitleTextAreaStyle"
                  id="titleArea"
                  style={{background: state.card_bg, color: state.text_color}}
                  rows="1"
                  cols="20"
                  onFocus={() => {
                    document.querySelector('#titleArea').style.background = 'lightyellow';
                    document.querySelector('#titleArea').style.color = 'black';
                  }}
                  onBlur={() => {
                    document.querySelector('#titleArea').style.background = state.card_bg;
                    document.querySelector('#titleArea').style.color = state.text_color;
                  }}
                  onChange={(e) => dispatch({type: 'change_title', payload: {title: e.target.value}})}
                  value={state.title}></textarea>
              </div>
              <div className="col_flex_aro">
                <label
                  className="formuleLabelStyle"
                  style={{color: 
                    ((state.text_mode === '2' && state.text_1.length >= 123)
                    ||
                    (state.text_mode === '1' && state.text_1.length >= 256))
                      ? 'red'
                      : state.text_color,
                  }}>
                  {state.text_mode === '2' && (<span>{state.text_1.length + ' / 123 caractères'}</span>)}
                  {state.text_mode === '1' && (<span>{state.text_1.length + ' / 256 caractères'}</span>)}
                </label>
                <textarea
                  className="textAreaStyle formuleTextTextAreaStyle"
                  id="text_1Area"
                  style={{background: state.card_bg, color: state.text_color}}
                  onFocus={() => {
                    document.querySelector('#text_1Area').style.background = 'lightyellow';
                    document.querySelector('#text_1Area').style.color = 'black';
                  }}
                  onBlur={() => {
                    document.querySelector('#text_1Area').style.background = state.card_bg;
                    document.querySelector('#text_1Area').style.color = state.text_color;
                  }}
                  rows={state.text_mode === '2' ? '4' : '8'}
                  cols="32"
                  onChange={(e) => dispatch({type: 'change_text_1', payload: {text_1: e.target.value}})}
                  value={state.text_1}></textarea>
              </div>
              {state.text_mode === '2' && (
                <div className="col_flex_aro">
                  <label
                    className="formuleLabelStyle"
                    style={{color: state.text_2.length >= 123 ? 'red' : state.text_color}}>
                      {state.text_2.length + ' / 123 caractères'}
                  </label>
                  <textarea
                    className="textAreaStyle formuleTextTextAreaStyle"
                    id="text_2Area"
                    style={{background: state.card_bg, color: state.text_color}}
                    onFocus={() => {
                      document.querySelector('#text_2Area').style.background = 'lightyellow';
                      document.querySelector('#text_2Area').style.color = 'black';
                    }}
                    onBlur={() => {
                      document.querySelector('#text_2Area').style.background = state.card_bg;
                      document.querySelector('#text_1Area').style.color = state.text_color;
                    }}
                    rows="4"
                    cols="32"
                    onChange={(e) => dispatch({type: 'change_text_2', payload: {text_2: e.target.value}})}
                    value={state.text_2}></textarea>
                </div>
              )}
              <div className="col_flex_aro">
                <label
                  className="formuleLabelStyle"
                  style={{color: state.ps.length >= 62 ? 'red' : state.text_color}}>
                    {state.ps.length + ' / 62 caractères'}
                </label>
                <textarea
                  className="textAreaStyle formuleTextTextAreaStyle"
                  id="psArea"
                  style={{background: state.card_bg, color: state.text_color}}
                  onFocus={() => {
                    document.querySelector('#psArea').style.background = 'lightyellow';
                    document.querySelector('#psArea').style.color = 'black';
                  }}
                  onBlur={() => {
                    document.querySelector('#psArea').style.background = state.card_bg;
                    document.querySelector('#psArea').style.color = state.text_color;
                  }}
                  rows="2"
                  cols="32"
                  onChange={(e) => dispatch({type: 'change_ps', payload: {ps: e.target.value}})}
                  value={state.ps}></textarea>
              </div>
            </div>
          ) : (
            <div className="col_flex_aro textContainer noselect">
              <div className="formuleTitleStyle" style={{color: state.text_color}}>
                {state.title}
              </div>
              <div className="formuleTextStyle" style={{color: state.text_color}}>
                <span style={{marginLeft: '30px'}}></span>
                {state.text_1}
              </div>
              <div className="formuleTextStyle" style={{color: state.text_color}}>
                <span style={{marginLeft: '30px'}}></span>
                {state.text_2}
              </div>
              <div className="formulePostStyle" style={{color: state.text_color}}>
                <span style={{marginLeft: '30px'}}></span>
                {state.ps}
              </div>
            </div>
          )}
          {state.image_place === 'right' && (
            <div
              className="row_flex_aro formuleImageContainer"
              style={{position: 'relative'}}>
              <img
                className="formuleImage"
                style={{
                  transform:
                    'translateX(' + state.image_pos_x + '%) translateY(' + state.image_pos_y + '%) scale(' + state.image_zoom +')',
                }}
                src={process.env.REACT_APP_BLT_IMAGE_URL + state.image}
                alt="Logo de AVR-FLASHER"/>
                {!['', 'Le fichier est enregistré.'].includes(state.message) && (
                  <span className="imgLoadingMessage">
                    {state.message}
                  </span>
                )}
                {state.adminMode && (
                  <input
                    type="file"
                    className="bltChangeImg"
                    onChange={(e) => changeBltImg(e.target.files[0], dispatch)} />
                )}
            </div>
          )}
        </div>
      )} 
      {state.adminMode && (
        <div className="col_flex_row">
          <div className={window.screen.width >= 650 ? "row_flex_aro bltChangeImg" : "col_flex_aro bltChangeImg"}>
            <label>Image : </label>
            <div className="row_flex_aro" style={{width: '100vw', maxWidth: '250px', marginTop: '12px'}}>
              <input
                type="range"
                min="0.2"
                max="2.5"
                step="0.1"
                style={{position: 'relative', top: '-2px'}}
                onChange={(e) => dispatch({type: 'change_image_zoom', payload: {image_zoom: e.target.value}})}
                value={state.image_zoom}
              />
              <label style={{paddingBottom: '4px'}}>coef : {state.image_zoom}</label>
            </div>
            <input
              type="file"
              style={{height: '25px', marginTop: '12px', paddingBottom: '8px'}}
              onChange={(e) => changeBltImg(e.target.files[0], dispatch)} />
          </div>
          <div className={window.screen.width >= 650
                ? "row_flex_aro noselect inputUnit"
                : "col_flex_aro noselect inputUnit"}>
            <div className="row_flew_aro">
              <label htmlFor="bg_color">Couleur de la carte</label>
              <input
                type="color"
                id="bg_color"
                style={{marginLeft: '15px'}}
                value={state.card_bg}
                onChange={(e) => dispatch({type: 'change_card_bg', payload: ({card_bg: e.target.value})})} />
            </div>
            <div className="row_flew_aro" style={{margin: '10px 0'}}>
              {window.screen.width >= 650 ? (
                <label htmlFor="text_color">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;et des textes</label>
              ) : (
                <label htmlFor="text_color">Couleur des textes</label>
              )}
              <input
                type="color"
                id="text_color"
                style={{marginLeft: '15px'}}
                value={state.text_color}
                onChange={(e) => dispatch({type: 'change_text_color', payload: ({text_color: e.target.value})})} />
            </div>
          </div>
          <div className={window.screen.width >= 650
                ? "row_flex_aro noselect inputUnit"
                : "col_flex_aro noselect inputUnit"}>
            <div className="row_flex_aro" style={{width: '310px', height: '40px'}}>
              <label htmlFor="leftRightBut" style={{margin: '0 18px'}}>2 textes ( 2x 123 caractères )</label>
              <input
                type="radio"
                name="textMode"
                style={{transform: 'scale(2.2)', position: 'relative', top: '2px'}}
                onChange={() => dispatch({type: 'change_text_mode', payload: ({text_mode: '2'})})}
                checked={state.text_mode === '2'}
                value="2"/>
            </div>
            {window.screen.width >= 650 && (
              <label htmlFor="leftRightBut" style={{margin: '0 18px'}}>
                &nbsp;&nbsp; ou &nbsp;&nbsp;
              </label>
            )}
            <div className="row_flex_aro" style={{width: '310px', height: '40px'}}>
              {window.screen.width <= 650 && (
                <label htmlFor="leftRightBut" style={{margin: '0 18px'}}>
                  1 texte ( 1 x 256 caractères )
                </label>
              )}
              <input
                type="radio"
                style={{transform: 'scale(2.2)', position: 'relative', top: '2px'}}
                name="textMode"
                onChange={() => dispatch({type: 'change_text_mode', payload: ({text_mode: '1'})})}
                checked={state.text_mode === '1'}
                value="1"/>
              {window.screen.width >= 650 && (
                <label htmlFor="leftRightBut" style={{margin: '0 18px'}}>
                  1 texte ( 1 x 256 caractères )
                </label>
              )}
            </div>
          </div>
          <div className={window.screen.width >= 650
                ? "row_flex_aro noselect inputUnit"
                : "col_flex_aro noselect inputUnit"}>
            <div className="row_flex_aro" style={{width: '310px', height: '40px'}}>
              <label htmlFor="leftRightBut" style={{margin: '0 18px'}}>Image à gauche</label>
              <input
                type="radio"
                name="leftRightBut"
                style={{transform: 'scale(2.2)', position: 'relative', top: '2px'}}
                onChange={() => {
                  dispatch({type: 'change_image_place', payload: ({side: 'left'})});
                  setTimeout(() => imageEvent(), 500);
                }}
                checked={state.image_place=== 'left'}
                value="left"/>
            </div>
            {window.screen.width >= 650 && (
              <label htmlFor="leftRightBut" style={{margin: '0 18px'}}>
                &nbsp;&nbsp; ou &nbsp;&nbsp;&nbsp;
              </label>
            )}
            <div className="row_flex_aro" style={{width: '310px', height: '40px'}}>
              {window.screen.width < 650 && (
                <label htmlFor="leftRightBut" style={{margin: '0 18px'}}>
                  Image à droite
                </label>
              )}
              <input
                type="radio"
                style={{transform: 'scale(2.2)', position: 'relative', top: '2px'}}
                name="leftRightBut"
                onChange={() => {
                  dispatch({type: 'change_image_place', payload: ({side: 'right'})});
                  setTimeout(() => imageEvent(), 500);
                }}
                checked={state.image_place === 'right'}
                value="right"/>
                {window.screen.width > 650 && (
                  <label htmlFor="leftRightBut" style={{margin: '0 18px'}}>
                    Image à droite
                  </label>
                )}
            </div>
          </div>


        </div>
      )}
      <div className="ttText" style={{marginTop: '50px'}}>
          <p className="flash_p">
              <span style={{marginLeft:'30px'}}></span>
              Vous savez maintenant comment le propriétaire d'une web app peut gérer ses contenus en autonomie. Il 
              en va de même pour les différents composants des diverses pages. 
              <br></br><br></br>
          </p>
      </div>
      <div>
        <img
        style={{maxWidth: '800px', width: '90vw', border: '1px solid grey'}}
          src={process.env.PUBLIC_URL + '/images/belevenement/lesFormules.png'}
          alt="Représentation des composants dans leur environnement"/>
      </div>
      <div className="ttText" style={{marginTop: '50px'}}>
          <p className="flash_p">
              <span style={{marginLeft:'30px'}}></span>
              Lors d'une connexion en mode administrateur, chaque composant est équipé de l'icône 
              "éditer" en bleu puis de la mention "En ligne". Lorsque l'administrateur clique sur 
              "En ligne", le composant passe hors ligne et ne sera donc pas exposé aux visiteurs. 
              Cette fonction permet de préparer des composants sans les exposer, ou de les exposer 
              par période avec un seul clic.
              <br></br><br></br>
          </p>
      </div>
      <div className={window.screen.width > 850 ? 'row_flex_aro' : 'col_flex_aro'} style={{width: '100vw', maxWidth: '900px'}}>
        <div>
          <img
          style={{maxWidth: '400px', width: '60vw', border: '1px solid grey'}}
            src={process.env.PUBLIC_URL + '/images/belevenement/formuleEnLigne.png'}
            alt="Représentation des composants dans leur environnement"/>
        </div>
        <div>
          <img
          style={{maxWidth: '400px', width: '60vw', border: '1px solid grey'}}
            src={process.env.PUBLIC_URL + '/images/belevenement/formuleHorsLigne.png'}
            alt="Représentation des composants dans leur environnement"/>
        </div>
      </div>
      <div className="ttText" style={{marginTop: '50px'}}>
          <p className="flash_p">
              <span style={{marginLeft:'30px'}}></span>
              Contactez moi pour parler de votre future web app.
              <br></br><br></br>
          </p>
      </div>
      <Link
        to="/contact"
        className="link contactBlt"
        onClick={() => {
          sendNavigationInfo('visit_contactpage');
        }}>Contact</Link>
      <br></br><br></br><br></br><br></br><br></br><br></br>
    </section>
  )
}
