export default function belevenementReducer(state, action) {
    switch (action.type) {
      case 'change_mode': {
        return {...state, adminMode: action.payload.adminMode};
      }
      case 'change_text_mode': {
        return {...state, text_mode: action.payload.text_mode};
      }
      case 'change_title': {
        if (action.payload.title.length <= 20) {
          return {...state, title: action.payload.title}
        } else {
          return {...state, title: state.title}
        }
      }
      case 'change_text_1': {
        if (state.text_mode === '2' && action.payload.text_1.length <= 123) {
          return {...state, text_1: action.payload.text_1}
        } else if (state.text_mode === '2'
          && action.payload.text_1.length > 123
          && action.payload.text_1.length < state.text_1.length) {
            return {...state, text_1: state.text_1.substring(0, 122)}
        } else if (state.text_mode === '1' && action.payload.text_1.length <= 256) {
          return {...state, text_1: action.payload.text_1}
        }  else {
          return {...state, text_1: state.text_1}
        }
      }
      case 'change_text_2': {
        if (action.payload.text_2.length <= 123) {
          return {...state, text_2: action.payload.text_2}
        } else {
          return {...state, text_2: state.text_2}
        }
      }
      case 'change_ps': {
        if (action.payload.ps.length <= 62) {
          return {...state, ps: action.payload.ps}
        } else {
          return {...state, ps: state.ps}
        }
      }
      case 'change_image_place': {
        return {...state, image_place: action.payload.side}
      }
      case 'change_card_bg': {
        return {...state, card_bg: action.payload.card_bg}
      }
      case 'change_text_color': {
        return {...state, text_color: action.payload.text_color}
      }
      case 'change_image': {
        return {
            ...state,
            image: action.payload.image,
            message: action.payload.message,
        }
      }
      case 'change_image_position': {
        if (state.adminMode) {
            return {
                ...state,
                image_pos_x: action.payload.image_pos_x,
                image_pos_y: action.payload.image_pos_y,
            }
        } else {
            return {
                ...state,
                image_pos_x: state.image_pos_x,
                image_pos_y: state.image_pos_y,
            }
        }
      }
      case 'change_image_zoom': {
        if (state.adminMode) {
            return {...state, image_zoom: action.payload.image_zoom}
        } else {
            return {...state, image_pos_x: state.image_zoom}
        }
      }
      default: break;
    }
    throw Error('Unknown action: ' + action.type);
  }