import React from 'react';
import './profileStyle.css';
import sendNavigationInfo from '../functions/sendNavigationInfo';

export default function WebDynamique(props) {
    const dynamicImages = [
      [
        process.env.PUBLIC_URL + '/images/competence/webDynamique/mg_img4_800x460.png',
        'Composants paramétrables dynamiquement, intégrez vos contenus et publiez. "belevenement.fr"',
        'Testez ce composants sur la page "Belévenement" de ce blog',
      ],
      [
        process.env.PUBLIC_URL + '/images/competence/webDynamique/mg_img3_800x460.png',
        'Rédigez vos articles, intégrez vos images, choisissez vos couleurs et publiez. "belevenement.fr"',
        'Testez ce composants sur la page "Belévenement" de ce blog',
      ],
      [
        process.env.PUBLIC_URL + '/images/competence/webDynamique/mg_img1_800x444.png',
        'Suivi de tâches, gestion de fichiers, compteur horaire en mode Drag&Drop',
        'Interface de suivi des tâches en mode Drag and Drop',
      ],
      [
        process.env.PUBLIC_URL + '/images/competence/webDynamique/mg_img2_800x457.png',
        'Gestion de stock en ligne, édition des devis et commandes sous Word, mise à jour dynamique du front end',
        'Gestion de stock en équipe, données dynamique, édition des demande de devis et commandes',
      ],
    ];
    return (
      <div className="noselect">
        <div className="centerH2">
          <h2 className="h2Title">Applications web dynamique <i>(REACTjs - JAVASCRIPT)</i></h2>
        </div>
        <div className="selectorToolContainer">
          <div onClick={() => {
            props.getPageBefore(dynamicImages.length);
            sendNavigationInfo('visit_skills_dynamique_beforebutton');
          }}>
            {props.icons.arrow_to_left}
          </div>
          <div onClick={() => {
            props.getPageAfter(dynamicImages.length);
            sendNavigationInfo('visit_skills_dynamique_afterbutton');
          }}>
            {props.icons.arrow_to_right}
          </div>
        </div>
        <div className="imageContainer">
          <div className="pythonContent">
            <img
              src={dynamicImages[props.staticSelector][0]}
              className="profilImage"
              alt={dynamicImages[props.staticSelector][2]} />
          </div>
          <div className="pythonContent description">
            <p>{dynamicImages[props.staticSelector][1]}</p>
          </div>
        </div>
      </div>
    )
  }