import axios from "axios";

async function changeBltImg (file, dispatch) {
    await axios({
        method: 'POST', 
        // url: 'http://localhost/fsapi/index.php',
        url: 'https://servtome.ddns.net/fsapi/index.php',
        params: {},
        data: {
            fileUpload: file,
            updateBltImage: 'updateImg',
        },
        headers: {
            'Content-Type': 'multipart/form-data',
        }
    }).then((resp) => {
        if (resp.status === 200) {console.log(resp.data.message)
            dispatch({
                type: 'change_image',
                payload:{
                    image: resp.data.filename,
                    message: resp.data.message,
            }});
        } else {
            dispatch({
                type: 'change_image',
                payload:{
                    image: 'belevenement_200x200.png',
                    message: '',
            }});
        }
    }).catch((e) =>
        dispatch({
            type: 'change_image',
            payload:{
                image: 'belevenement_200x200.png',
                messsage: '',
        }}))
}
export default changeBltImg;