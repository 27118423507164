import axios from "axios";

async function sendNavigationInfo (localisation) { // console.log(localisation)
    await axios({
        method: 'POST', 
        // url: 'http://localhost/PORTFOLIO_DATA/index.php',
        url: 'https://servtome.ddns.net/PORTFOLIO_DATA/index.php',
        params: {},
        data: {nav: localisation},
        headers: {
            'Content-Type': 'application/json',
        }
    }).then((resp) => {
        if (resp.status === 200) {
            // console.log(resp.data)
        } else {
            // setKmlMessage({...kmlMessage, message: "Problème de réseau, désolé"});
        }
    }).catch((e) => console.log(e))
}
export default sendNavigationInfo;