import React, {useState, useEffect} from 'react';
import {Link} from "react-router-dom";
import CardComponent from './CardComponent';
import sendNavigationInfo from '../functions/sendNavigationInfo';
import './HomeStyle.css';

export default function HomePage(props) {
  const [screenWidth, setScreenWidth] = useState('larg');
  const [display, setDisplay] = useState(false);
  useEffect(() => {
    setScreenWidth(window.screen.width > 1000 ? 'larg' : 'small');
    setTimeout(() => setDisplay(true), 100);
    return;
  }, [])
  return (
    <>
      {display && (
        <section className="homeContainer">
          <Link to={'/LiveChat'} style={{textDecoration: 'none', color: 'black'}}>
            <CardComponent 
              title={props.homePageData.item_5.title}
              pageLink={props.homePageData.item_5.pageLink}
              description={props.homePageData.item_5.description}
              image={
                screenWidth === 'larg'
                  ? props.homePageData.item_5.image_larg
                  : props.homePageData.item_5.image_small
              }
              image_alt={props.homePageData.item_5.image_alt}
              redirection={props.homePageData.item_5.redirection}
              setActivePage={props.setActivePage}
              changePage={props.changePage}
              sendNavigationInfo={sendNavigationInfo}
              localisation={'visit_livechat'}
            />
          </Link>
          <Link to={'/profil'} style={{textDecoration: 'none', color: 'black'}}>
            <CardComponent 
              title={props.homePageData.item_2.title}
              pageLink={props.homePageData.item_2.pageLink}
              description={props.homePageData.item_2.description}
              image={
                screenWidth === 'larg'
                  ? props.homePageData.item_2.image_larg
                  : props.homePageData.item_2.image_small
              }
              image_alt={props.homePageData.item_2.image_alt}
              redirection={props.homePageData.item_2.redirection}
              setActivePage={props.setActivePage}
              changePage={props.changePage}
              sendNavigationInfo={sendNavigationInfo}
              localisation={'visit_skills'}
            />
          </Link>
          <Link to={'/belevenement'} style={{textDecoration: 'none', color: 'black'}}>
            <CardComponent 
              title={props.homePageData.item_7.title}
              pageLink={props.homePageData.item_7.pageLink}
              description={props.homePageData.item_7.description}
              image={
                screenWidth === 'larg'
                  ? props.homePageData.item_7.image_larg
                  : props.homePageData.item_7.image_small
              }
              image_alt={props.homePageData.item_7.image_alt}
              redirection={props.homePageData.item_7.redirection}
              setActivePage={props.setActivePage}
              changePage={props.changePage}
              sendNavigationInfo={sendNavigationInfo}
              localisation={'visit_skills'}
            />
          </Link>
          <Link to={'/dynamicMap'} style={{textDecoration: 'none', color: 'black'}}>
            <CardComponent 
              title={props.homePageData.item_6.title}
              pageLink={props.homePageData.item_6.pageLink}
              description={props.homePageData.item_6.description}
              image={
                screenWidth === 'larg'
                  ? props.homePageData.item_6.image_larg
                  : props.homePageData.item_6.image_small
              }
              image_alt={props.homePageData.item_6.image_alt}
              redirection={props.homePageData.item_6.redirection}
              setActivePage={props.setActivePage}
              changePage={props.changePage}
              sendNavigationInfo={sendNavigationInfo}
              localisation={'visit_dynamicMap'}
            />
          </Link>
          <a
            href={props.homePageData.item_3.redirection}
            onClick={() => sendNavigationInfo('visit_artistWebsite')}
            target="_blank"
            rel="noreferrer"
            style={{textDecoration: 'none', color: 'black'}}>
            <CardComponent 
              title={props.homePageData.item_3.title}
              pageLink={'home'}
              description={props.homePageData.item_3.description}
              image={
                screenWidth === 'larg'
                  ? props.homePageData.item_3.image_larg
                  : props.homePageData.item_3.image_small
              }
              image_alt={props.homePageData.item_3.image_alt}
              redirection={props.homePageData.item_3.redirection}
              setActivePage={props.setActivePage}
              changePage={props.changePage}
              sendNavigationInfo={sendNavigationInfo}
            />    
          </a>
          <Link to={'/AVR-FLASHER'} style={{textDecoration: 'none', color: 'black'}}>
            <CardComponent 
              title={props.homePageData.item_1.title}
              pageLink={props.homePageData.item_1.pageLink}
              description={props.homePageData.item_1.description}
              image={
                screenWidth === 'larg'
                  ? props.homePageData.item_1.image_larg
                  : props.homePageData.item_1.image_small
              }
              image_alt={props.homePageData.item_1.image_alt}
              redirection={props.homePageData.item_1.redirection}
              setActivePage={props.setActivePage}
              changePage={props.changePage}
              sendNavigationInfo={sendNavigationInfo}
              localisation={'visit_AVR_FLASHER'}
            />
          </Link>
          <Link to={'/AT-MANAGER'} style={{textDecoration: 'none', color: 'black'}}>
            <CardComponent 
              title={props.homePageData.item_4.title}
              pageLink={props.homePageData.item_4.pageLink}
              description={props.homePageData.item_4.description}
              image={
                screenWidth === 'larg'
                  ? props.homePageData.item_4.image_larg
                  : props.homePageData.item_4.image_small
              }
              image_alt={props.homePageData.item_4.image_alt}
              redirection={props.homePageData.item_4.redirection}
              setActivePage={props.setActivePage}
              changePage={props.changePage}
              sendNavigationInfo={sendNavigationInfo}
              localisation={'visit_AT_MANAGER'}
            />
          </Link>
        </section>
      )}
    </>
  )
}
